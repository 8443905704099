<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 霍兰德测评 -->
        <div class="testbox">
            <div class="testcon">
                <div class="testleft">
                    <div class="t1">MBTI职业性格测评</div>
                    <img src="../../../assets/evaluation/evaluation_MBTI_1.jpg" class="img" alt="" />
                </div>
                <div class="testright">
                    <span><span style="color: rgba(var(--themecolor),0.75);font-size: 20px;font-weight: bold;">引导语：</span></span><br/>
                    <span>“我性格内向/外向，适合什么工作？”“哪些职业正好匹配我的性格？”“以我的个性从事什么行业好？”“我性格中的优势和劣势是什么？” 很多人，特别是即将进入大学选择专业的学生，面对这类问题都会感到困惑——性格因素和职业选择之间到底存在什么样的关联呢？</span><br/><br/>
                    <span><span style="color: rgba(var(--themecolor),0.75);font-size: 20px;font-weight: bold;">要回答诸如此类的问题，我们就必须先了解到底性格差异是如何形成的。</span></span><br/>
                    <span>瑞士心理学家荣格(Carl Jung)认为：感知和判断是大脑的两大基本功能。大脑做决定的瞬间可以慢动作分解为两个阶段：感知阶段（又分为触觉感知阶段和直觉感知阶段）和判断阶段（又分为感性判断和理性判断阶段）。 </span><br/>
                    <span>为方便我们的理解，我们把大脑做出决定的瞬间直观想象为如下流程：（大脑获取信息后）触觉感知——直觉感知——感性判断——理性判断，最后做出决定。 不过请记住实际上这一过程是在瞬间交织（并非想象中简单的线性）完成的。  </span><br/>
                    <span>虽然每个人的大脑做出决定的瞬间都要走这四个流程，但是不同的人在其中某个环节中的倾向程度不同（也可以理解为滞留时间长短不同）：有些人更倾向停留在触觉感知环节多一些，而直觉感知一带而过；有些人在判断环节，更倾向停留在感性判断多一些，理性判断一带而过。 此外，大脑的这两大基本功能还受到每个人的精力来源不同与生活方式差异的影响（由美国心理学家Katherine Cook Briggs 提出），最终的决定就千差万别了。经过多年的实践和不断优化，荣格的人格分类理论已成为目前国际上有数据支撑的性格分类模型的理论基础。  </span><br/>
                </div>
                <div class="clear"></div>
                <div class="testleft" style="float: right;margin-left: 0px;margin-right: 30px;">
                    <img src="../../../assets/evaluation/evaluation_MBTI_2.jpg" class="img" alt="" />
                    <div class="t2">MBTI</div>
                </div>
                <div class="testright" style="float: left;margin-right: 0px;margin-left: 30px;">
                    <span>以荣格的《人格分类》理论为基础，美国的心理学家Katherine Cook Briggs (1875-1968) 和她的心理学家女儿Isabel Briggs Myers根据她们对于人类性格差异的长期观察和研究，提出了影响大脑作出决定的第四因素：生活方式。综合荣格的人格分类学说形成MBTI。MBTI是一种迫选型、自我报告式的性格评估理论模型，用以衡量和描述人们在获取信息、作出决策、对待生活等方面的心理活动规律和性格类型。</span><br/><br/>
                    <span><span style="color: rgba(var(--themecolor),0.75);font-size: 20px;font-weight: bold;">通过MBTI模型，性格和职业之间的联系得到了比较清晰地阐释。</span></span><br/>
                    <span>比如到达一定倾向度的NT型更适合做研究开发类职业（或从事此类职业更容易激发其天然优势），而不是从事销售或客服。到达一定倾向度的STJ更适合从事管理行政类职业，而不适合艺术、设计类职业...经过70多年的实践和发展，MBTI现在已经广泛应用到企业招聘选聘心理学测量、内部人才盘点及职业规划、职业测试等众多领域。</span><br/>
                    <span>我们根据美国Eastcarolina大学心理学专业的MBTI-G版本(量表版本：1998-N）的改编量表。希望帮助大家找到了解自己的线索。对于确定人生第一份职业选择的高中毕业生来说，用它可以辅助专业选择。</span><br/><br/>
                    <span><span style="color: rgba(var(--themecolor),0.75);font-size: 20px;font-weight: bold;">特别说明：</span></span><br/>
                    <span>性格没有好与坏，测试的目的是反映最真实的自己，而不是别人所期待的你。请最大程度放松下来，选择当您面临下述这些情况时不由自主、自然和不假思索的决定或倾向。</span>
                </div>
                <div class="clear"></div>
                <div class="btn" @click.prevent="$router.push('/answer?evaluationtype=1')">开始测评</div>
            </div>
            
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.testbox{
    max-width: 1420px;
    margin: auto;
    .testcon{
        margin: 20px 20px 30px;
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 60px;
        .testleft{
            width: 400px;
            float: left;
            margin-left: 30px;
            text-align: center;
            .t1{
                color: #555;
                font-size: 30px;
            }
            .t2{
                color: rgba(var(--themecolor),0.75);
                font-size: 22px;
            }
            .img{
                display: block;
                margin: 30px auto  30px;
            }
        }
        .testright{
            width:calc(100% - 480px);
            float: right;
            margin-right: 30px;
            color: #666;
            font-size: 14px;
            line-height: 24px;
            margin-top: 3px;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            background-color: rgba(var(--themecolor),0.85);
            margin: 60px auto 0px;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            border-radius: 5px;
        }
        .btn:hover{
            cursor: pointer;
            background-color: rgba(var(--themecolor),1);
        }
    }
}
</style>